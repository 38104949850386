
import { defineComponent, onMounted, ref, reactive } from 'vue'
import { MaintainCellProps, MaintainColProps, MaintainChildren } from './types'
import { ColmunsProps, ColmunsChildrenProps } from '@/API/types'
import { getCalculatorOperation, updateState, getCustomerType, getBrandList, getAllBu, getBrandByBu } from '@/API/finance'
import { message } from 'ant-design-vue';
import useTableHeight from "@/hooks/useTableHeight";
import { findColumnIdBytitle } from '@/utils'
interface StatusParams {
    typeClassCode: string;
    modelYear: string;
    model: string;
}

interface OperationParams {
  status: string;
  offerType: string;
  bu: string;
  brand: string;
  customerType: string;
  offer: string;
}
export default defineComponent({
    name: 'Calculator Confirm',
    setup() {
        // 标识当前的类型 fa 、buy down
        const offerType = ref('fa')
        const { tableHeight } = useTableHeight(false);

        // 当选中项发生变化
        const selectedKeys = ref()
        // 配置选择框
        const rowSelection = {
            onChange: (selectedRowKeys: []) => {
                selectedKeys.value = selectedRowKeys
            },
            // fixed: true,
            hideDefaultSelections: true,
        }
        const valueBrand = ref<string>('')
        // 获取children中的数据，进行配置
        const deployChild = (arr: MaintainChildren[], type?: string): ColmunsChildrenProps[] => {
            const children: ColmunsChildrenProps[] = []
            arr.forEach((item: MaintainChildren) => {
                if (type === 'fixed') {
                  if (item.display === 'Model') {
                    children.push({
                      title: item.display,
                      dataIndex: item.id,
                      key: item.id,
                      width: valueBrand.value !== 'MB' ? 230 : 150,
                      align: 'center'
                    })
                  } else {
                    children.push({
                      title: item.display,
                      dataIndex: item.id,
                      key: item.id,
                      width: 130,
                      align: 'center'
                    })
                  }
                } else {
                  children.push({
                    title: item.display,
                    dataIndex: item.id,
                    key: item.id,
                    slots: {customRender: item.id },
                    width: 150,
                    align: 'center'
                  })
              }
            })
            return children
        }

        const findColLength = (data: MaintainColProps[]): number => {
            const colArr: string[] = []
            const childrenArr: any[] = []
            data.forEach((col) => {
                for (const key in col) {
                    if (key === 'children') {
                        childrenArr.push(col[key])
                    }
                }
            })
            childrenArr.flat().forEach(item => {
                colArr.push(item.dataIndex)
            })
            return childrenArr.flat().length
        }

        const columns = ref<ColmunsProps[]>([])
        const configColumns = (data: MaintainColProps[]) => {
          columns.value = []
          const length = findColLength(data)
          data.forEach((item: MaintainColProps, index: number) => {
            //判断是否是第一个 最后一个，并是否有中间滚动的部分
            if (index === 0 && data.length > 3 && length > 8) {
                columns.value.push({
                  title: item.display,
                  fixed: 'left',
                  children: deployChild(item.children, 'fixed')
                })
            } else if (index === data.length - 1 && data.length > 3 && length > 8) {
                columns.value.push({
                  title: item.display,
                  fixed: 'right',
                  children: deployChild(item.children, 'fixed')
                })
            } else if (index === 0){
               columns.value.push({
                  title: item.display,
                  children: deployChild(item.children, 'fixed')
                })
            } else if (index === data.length - 1) {
                columns.value.push({
                  title: item.display,
                  children: deployChild(item.children, 'fixed')
                })
            } else {
                 columns.value.push({
                  title: item.display,
                  children: deployChild(item.children),
                  align: 'center'
                })
            }
            if (data.length === 2) {
              columns.value.splice(1, 0, {
                title: '',
                dataIndex: '',
              })
            }
          })
        }

        
        const valueBu = ref<string>('')
        const valueCustomer = ref('')
        const valueMark = ref('')
      
        // 获取sheetName
        const sheetName: string[] = []
        const findSheetName = (data: MaintainColProps[]) => {
          data.forEach((item, index) => {
            if (index !== 0 && index !== data.length - 1) {
              sheetName.push(item.display)
            }
          })
        }

        // 导航的数据
        const activeKey = ref('normal')
        // 初始化数据
        const brandsSelect = ref()
        const buSelect = ref()
        const buyBrandSelect = ref()
        const customerSelect = ref()
        const markSelect = reactive(['Order Protection', 'Pre Sale'])
       
        // 获取下拉选择内容
        const getSelectData = async() => {
            // CustomerType
            customerSelect.value = await getCustomerType()
            // 获取brand list FA
            brandsSelect.value = await getBrandList()
            valueBrand.value = brandsSelect.value[0]

            // 获取bu 
            buSelect.value = await getAllBu()
        }

        // 获取表格的数据
        const colArr = ref<string[]>([])
        const dataSource = ref()
        const getTableData = () => {
          const params: OperationParams = {
            offer: valueMark.value,
            offerType: offerType.value,
            brand: valueBrand.value,
            bu: valueBu.value,
            customerType: valueCustomer.value,
            status: 'reopen'
          }
        
          getCalculatorOperation({params}).then((res) => {
              //根据id排序
              res.maintain.tableCol.sort((x: any, y: any) => {
                  return x.id - y.id
              })
              if (valueBrand.value !== 'MB') {
                dataSource.value = res.maintain.tableCell.filter((item) => item.id !== item.parentId)
              } else {
                dataSource.value = res.maintain.tableCell.filter((item) => item.id === item.parentId)
              }
              if (dataSource.value.length > 0) colArr.value = Object.keys(dataSource.value[0])
              // 获取sheetName
              findSheetName(res.maintain.tableCol)
              // 配置columns
              configColumns(res.maintain.tableCol)
            })
        }

        // 查询计算器数据
        const onSearch = () => {
            getTableData()
        }

        // 初始化数据
        const init = async () => {
            await getSelectData()
            getTableData()
        }

        // 获取选中的参数
        const getParams = (): StatusParams[] => {
            const params: StatusParams[] = []
            const arr = dataSource.value.filter((item: MaintainCellProps) => (selectedKeys.value).includes(item.id))
            arr.forEach((item: MaintainCellProps) => {
                params.push({
                    typeClassCode: item[findColumnIdBytitle('Type Class', columns.value)],
                    modelYear: item[findColumnIdBytitle('Model Year', columns.value)],
                    model: item[findColumnIdBytitle('Model', columns.value)],
                })
            })
            return params
        }

        // 切换面板
        const cutTabs = () => {
          if (activeKey.value === 'normal') {
            offerType.value = 'fa'
            valueBrand.value = brandsSelect.value[0]

          } else {
            valueBu.value = ''
            valueBrand.value = ''
            valueCustomer.value = ''
            valueMark.value = ''
            offerType.value = 'buydown'
          }
          getTableData()
        }

        // 切换BU
        const handleSelectBu = (value: string) => {
            valueBrand.value = ''
            buyBrandSelect.value = []
            // 根据bu 获取brand list
            const params = {
              bu: valueBu.value,
              target: "Vehicle.brand"
            }
            getBrandByBu(params).then((res: any) => {
              buyBrandSelect.value = res
            })
        }

        const handleSelectBrand = () => {
          getTableData()
        }

        // 点击按钮确认更改状态
        const visibleTip = ref(false)
        const changeStatus = () => {
          visibleTip.value = true
        }

        // 确认修改当前的状态
        const onChangeStatus = () => {
          const configParams = {
            offer: valueMark.value,
            offerType: offerType.value,
            brand: valueBrand.value,
            bu: valueBu.value,
            customerType: valueCustomer.value,
            status: 'reopen'
          }
          if (getParams().length > 0) {
            updateState(configParams, getParams()).then(() => {
               visibleTip.value = false
              getTableData()
            })
          } else {
            message.error("请选择至少一项数据")
          }
        }

        onMounted(() => {
            init()
        })
         return {
            tableHeight,
            columns,
            rowSelection,
            dataSource,
            valueBrand,
            cutTabs,
            handleSelectBrand,

            changeStatus,
            visibleTip,
            onChangeStatus,
            colArr,
            activeKey,
            selectedKeys,

            onSearch,
            buSelect,
            brandsSelect,
            customerSelect,
            markSelect,
            buyBrandSelect,
            valueBu,
            valueMark,
            valueCustomer,
            handleSelectBu,
         }

    }
 });
